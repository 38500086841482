/**
 * Loading required JS scripts and plugins such as jQuery and Bootstrap
 */

window._ = require('lodash');
require('bootstrap');
require('es6-promise').polyfill();

/**
 * Loading theme JS plugins
 */

// require("@fancyapps/fancybox");

// Require inputmask using jquery
require("inputmask/dist/inputmask/jquery.inputmask");
require("inputmask/dist/inputmask/inputmask.date.extensions");
window.Inputmask = require('inputmask');

/**
 * Loading theme custom JS
 */

require("./theme-scripts");
