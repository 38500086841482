jQuery(document).ready(function ($) {
  /** =============================================================== **/
  /** Handle sticky menu on scroll **/
  /** =============================================================== **/

  $(window).on("scroll", function () {
    $("header").toggleClass("pin", $(window).scrollTop() >= 15);
  });
  $(window).trigger("scroll");

  /** =============================================================== **/
  /** Scroll to id **/
  /** =============================================================== **/

  $(".scrollto").on("click", function (event) {
    event.preventDefault();
    var defaultAnchorOffset = 70;
    var anchor = $(this).attr("target");

    var anchorOffset = $("#" + anchor).attr("data-scroll-offset");
    if (!anchorOffset) anchorOffset = defaultAnchorOffset;

    $("html,body").animate(
      {
        scrollTop: $("#" + anchor).offset().top - anchorOffset,
      },
      200
    );
  });

  /** =============================================================== **/
  /** Menu toogler on mobile hamburger class change **/
  /** =============================================================== **/

  $(".hamburger").on("click", function (e) {
    e.preventDefault();
    $(this).toggleClass("is-active");
    $("body").toggleClass("overflow-hidden");
  });

  $("header .navbar-nav .nav-item .collapse-open").on("click", function (e) {
    e.preventDefault();
    $(this).siblings(".nav-link").toggleClass("active");
  });

  /** =============================================================== **/
  /** Agents more details click **/
  /** =============================================================== **/

  $(document).on("mouseup", function (e) {
    var container = $(".agents-box .single .details");
    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.removeClass("opened");
    }
  });

  $(".open-details").on("click", function (e) {
    e.preventDefault();
    $(this).closest(".single").find(".details").addClass("opened");
    $("html, body").animate(
      {
        scrollTop:
          $(this).closest(".single").offset().top -
          $("header").innerHeight() -
          30,
      },
      "slow"
    );
  });

  $(".agents-box .details .close").on("click", function (e) {
    e.preventDefault();
    $(this).closest(".single").find(".details").removeClass("opened");
  });

  /** ============================================= **/
  /** Init Inputmask **/
  /** ============================================= **/

  // jQuery(".phone-mask").inputmask({
  //     "mask": "(999) 999-9999"
  // });

  /** =============================================================== **/
  /** Accessibility Improvements **/
  /** =============================================================== **/

  // Determine accessibility focus based on mouse/keyboard use
  document.body.addEventListener("mousedown", function () {
    document.body.classList.add("using-mouse");
  });
  document.body.addEventListener("keydown", function () {
    document.body.classList.remove("using-mouse");
  });

  /** =============================================================== **/
  /** Read More Feature **/
  /** =============================================================== **/

  $(".read-more-expend").on("click", function (event) {
    event.preventDefault();
    let $moreSection = $(this).closest(".col-12").find("[data-initial-hidden]");
    if ($(this).data("status") == "closed") {
      $moreSection.slideDown("slow");
      $(this).data("status", "open");
      $(this).text($(this).data("readless"));
    } else {
      $moreSection.slideUp("slow");
      $(this).data("status", "closed");
      $(this).text($(this).data("readmore"));
    }
  });

  /** =============================================================== **/
  /** Communities Gallery **/
  /** =============================================================== **/

  $(".open-gallery").on("click", function () {
    event.preventDefault();
    $('[data-fancybox="gallery"]:first').trigger("click");
  });

  /** =============================================================== **/
  /** About Testimonials ReadMore **/
  /** =============================================================== **/
  $(".read-more").on("click", function (e) {
    $(this).parent().siblings("#dots1").toggleClass("d-none");
    $(this).parent().siblings(".more").toggleClass("d-none");
    e.preventDefault();
    $(".read-more").each(function (i, data) {
      if ($(data).text() == "READ MORE") {
        $(data).text("READ LESS");
        $(data).addClass("less-arrow");
      } else if ($(data).text() == "READ LESS") {
        $(data).text("READ MORE");
        $(data).removeClass("less-arrow");
      }
    });
  });
});
